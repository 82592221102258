import * as React from "react";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import copyOutline from "@iconify/icons-eva/copy-outline";
import copy from "copy-to-clipboard";
import styled from "styled-components";
import { compactAddress } from "../contractData/function";
import { toaster } from "../utils/toast";
import ArrowDownFill from "@iconify/icons-eva/arrow-down-fill";
import Collapse from "@kunukn/react-collapse";
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { UserListHead, UserMoreMenu } from "../components/_dashboard/user";
import USERLIST from "../_mocks_/user";
import { services } from "../services";
import { TimeStampToDateString } from "src/contractData/function";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "srNo", label: "Sr No", alignRight: false },
  { id: "poolName", label: "Pool Name", alignRight: false },
  { id: "contractAddress", label: "Contract Address", alignRight: false },
  { id: "tokenAddress", label: "Token Address", alignRight: false },
  { id: "network", label: "Network", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "phaseNo", label: "Phase No", alignRight: false },
  { id: "rewards", label: "Rewards", alignRight: false },
  { id: "pending", label: "Pending Records", alignRight: false },
  { id: "" },
];

export default function User() {
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState("");
  const [order, setOrder] = useState("asc");
  const [dropdownChange, setDropdownChange] = useState("Completed");
  const [orderBy, setOrderBy] = useState("name");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [claim, setClaim] = useState([]);
  const [pendingData, setPendingData] = useState(false);
  const [isDeployedPools, setIsDeployedPools] = useState(false);
  const [deployedPools, setDeployedPools] = useState([]);
  const [selectedBtn, setSelectedBtn] = useState("alreadyUpdated");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchData(page + 1);
    callDeployedPools(page + 1);
  }, [page]);
  async function fetchData(page) {
    const url = `/claim/dump-list?page=${page}`;
    const sendRequest = await services.Get(url);
    console.log(sendRequest);
    if (sendRequest.status === 200) {
      let data = sendRequest.data.data;
      setIsDeployedPools(false);
      setPendingData(data);
      return true;
    }
  }

  const isUserNotFound = claim.length === 0;

  const callDeployedPools = async (page) => {
    const url = `/claim/list?page=${page}`;
    const sendRequest = await services.Get(url);
    console.log(sendRequest);
    if (sendRequest.status === 200) {
      let data = sendRequest.data.data;
      setTotalPage(sendRequest.data.pagination.totalRecords);
      setIsDeployedPools(true);
      setDeployedPools(data);
      setClaim(data);
      window.scrollTo(0, 0);
      setDropdownChange("Completed");
    }
  };
  const isActive = (btn) => {
    if (btn === selectedBtn) return "active";
  };
  const isToggleClick = (checked, _id) => {
    dumpAction(!checked, _id);
  };
  const dumpAction = async (status, _id) => {
    const url = "/claim/edit";
    const payload = { claimId: _id, isDisabledBit: status };
    const sendRequest = await services.Post(url, payload);
    if (sendRequest.status === 200) {
      return toaster(`${"Pool Status Updated Successfully"}`, "success");
    }
  };

  const copyToClipboard = (address) => {
    copy(address);
    return toaster(`${"Copied!"}`, "success");
  };

  const [isOpen1, setIsOpen1] = React.useState(false);

  const onInit = () => {
    setIsOpen1(false);
  };
  const applyFilter = (type) => {
    const _data = deployedPools.filter((ele) => ele.vestingType === type);
    console.log(_data, type);
    setClaim(_data);
    onInit();
    setDropdownChange(type);
  };

  console.log(claim);
  return (
    <Page title="Manage Claims | Seedify Fund Snapshot">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Claim Pools
          </Typography>
          <ButtonList>
            <Button
              variant="contained"
              className={isActive("pending")}
              // component={RouterLink}
              to=""
              onClick={() => {
                setClaim(pendingData);
                setSelectedBtn("pending");
              }}
            >
              Upcoming
            </Button>
            <Button
              variant="contained"
              className={isActive("pending")}
              to=""
              onClick={() => {
                setClaim(pendingData);
                setSelectedBtn("pending");
              }}
            >
              Pending
            </Button>

            <Button
              className={isActive("alreadyUpdated")}
              variant="contained"
              to=""
              onClick={() => {
                setClaim(deployedPools);
                setSelectedBtn("alreadyUpdated");
                setIsOpen1((state) => !state);
                setDropdownChange("Completed");
              }}
            >
              {dropdownChange}
              <Icon
                icon={ArrowDownFill}
                width={18}
                height={18}
                onClick={() => setIsOpen1((state) => !state)}
              />
            </Button>
            <CompletedBox>
              <Collapse onInit={() => onInit()} isOpen={isOpen1}>
                <ul>
                  <li onClick={() => applyFilter("linear")}>Linear</li>
                  <li onClick={() => applyFilter("monthly")}>Monthly</li>
                  <li onClick={() => applyFilter("merkle")}>Merkle</li>
                </ul>
              </Collapse>
            </CompletedBox>
          </ButtonList>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {claim.map((row, index) => {
                    const {
                      _id,
                      name,
                      contractAddress,
                      tokenAddress,
                      networkName,
                      timestamp,
                      phaseNo,
                      amount,
                      data,
                      isDisabledBit,
                      dumpId,
                      isPending,
                    } = row;
                    return (
                      <TableRow hover key={_id} tabIndex={-1}>
                        <TableCell
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                          spacing={2}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                        >
                          {compactAddress(contractAddress)}
                          <span
                            style={{
                              position: "relative",
                              top: "4px",
                              cursor: "pointer",
                            }}
                          >
                            <Icon
                              icon={copyOutline}
                              onClick={() => copyToClipboard(contractAddress)}
                              height={18}
                              width={18}
                            />
                          </span>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                        >
                          {compactAddress(tokenAddress)}
                          <span
                            style={{
                              position: "relative",
                              top: "4px",
                              cursor: "pointer",
                            }}
                          >
                            <Icon
                              icon={copyOutline}
                              onClick={() => copyToClipboard(tokenAddress)}
                              height={18}
                              width={18}
                            />
                          </span>
                        </TableCell>
                        <TableCell
                          style={{
                            padding: "14px",
                            whiteSpace: "nowrap",
                            textTransform: "capitalize",
                          }}
                          align="left"
                        >
                          {" "}
                          {networkName}{" "}
                        </TableCell>
                        <TableCell
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                          align="left"
                        >
                          {TimeStampToDateString(timestamp)}
                        </TableCell>
                        <TableCell
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                          align="left"
                        >
                          {phaseNo}
                        </TableCell>
                        <TableCell
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                          align="left"
                        >
                          {amount}
                        </TableCell>
                        <TableCell
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                          align="left"
                        >
                          {selectedBtn == "pending" ? (data ? data : "") : "-"}
                        </TableCell>
                        {/* <TableCell align='right'>{data.length}</TableCell> */}
                        {selectedBtn === "alreadyUpdated" && (
                          <TableCell
                            align="center"
                            style={{ padding: "14px", whiteSpace: "nowrap" }}
                          >
                            <CustomSwitch>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  value={_id}
                                  defaultChecked={!isDisabledBit}
                                  onChange={(e) =>
                                    isToggleClick(
                                      e.target.checked,
                                      e.target.value
                                    )
                                  }
                                />
                                <span className="slider round"></span>
                              </label>
                            </CustomSwitch>
                          </TableCell>
                        )}
                        <TableCell
                          align="right"
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                        >
                          <UserMoreMenu
                            menu="claimPool"
                            id={dumpId?._id ? dumpId._id : _id}
                            url={
                              networkName === "solana"
                                ? selectedBtn === "pending"
                                  ? `/dashboard/edit-pending-solana-pool/${
                                      dumpId?._id || _id
                                    }`
                                  : `/dashboard/edit-completed-solana-pool/${
                                      dumpId?._id || _id
                                    }`
                                : selectedBtn === "pending"
                                ? `/dashboard/edit-pending-claim-pool/${
                                    dumpId?._id || _id
                                  }`
                                : `/dashboard/edit-claim-pool/${
                                    dumpId?._id || _id
                                  }`
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <LoaderOuter>
                          <div className="loader"></div>
                        </LoaderOuter>
                        <SearchNotFound />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[20]}
            component="div"
            count={totalPage}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}

const CustomSwitch = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

const LoaderOuter = styled.div`
  dispaly: flex;
  align-items: center;
  justify-content: center;
  .loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #5bb3fc;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    margin: 20px auto;
    animation: spin 0.5s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const ButtonList = styled.div`
  display: flex;
  align-items: center;
  Button {
    margin: 0px 5px;
    &.active {
      background-color: #048cfc;
    }
  }
`;

const MetamaskBTN = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  padding: 10px 30px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  img {
    width: 45px;
    margin-right: 10px;
  }
`;

const CompletedBox = styled.div`
  position: relative;
  .collapse-css-transition {
    position: absolute;
    top: 22px;
    right: 0px;
    left: auto;
    z-index: 9;
    width: 200px;
    transition: height 252ms cubic-bezier(0.4, 0, 0.2, 1);
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  li {
    padding: 10px;
    cursor: pointer;
  }
`;
