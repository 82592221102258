import { BigNumber, parseFixed } from "@ethersproject/bignumber";
import link2Outline from "@iconify/icons-eva/link-2-outline";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import "react-datetime/css/react-datetime.css";
import { axiosSeedify, axiosSeedworld } from "src/config";
import { compactAddress, utcToLocalTimeStamp } from "src/contractData/function";
import readFile from "src/helper/helperFunction";
import { web3 } from "src/web3";
import styled from "styled-components";
import Page from "../components/Page";
import { CHAIN_ID_LIST, NETWORK_DATA, NETWORK_LIST } from "../config/networks";
import {
  getContractAbi,
  getContractByteCode,
  getContractInstanceAbi,
  getTokenInstance,
} from "../helper/contractHelper";
import { services } from "../services/index";
import { web3Services } from "../services/web3.service";
import { toaster } from "../utils/toast";
import BigInt from "big-integer";
import { LAYER_ZERO_CONFIG } from "src/config/layerZero";

const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
const HALF_MINUTE_IN_MILLIS = 30000;

const CONTRACT_TYPES = {
  AUTO_REFUNDS: "auto-refunds",
  AUTO_REFUNDS_CROSS_CHAIN: "auto-refunds-cross-chain",
};

const AddClaimPools = ({ isCompleted }) => {
  const [isCreatingContract, setIsCreatingContract] = useState(false);
  const [isProcessingTransaction, setIsProcessingTransaction] = useState(false);
  const [isSWORLD, setIsSWORLD] = useState(false);

  const [web3Data, setWeb3Data] = useState({
    isLoggedIn: false,
    accounts: [],
  });

  const [state, setState] = useState({
    tokenAddress: "",
    idoName: "",
    previousAmount: 0,
    totalAmount: 0,
    network: "",
    claimData: [],
    contractAddress: "",
    utcStartDate: Math.round(new Date().getTime() / 1000),
    utcEndDate: Math.round(new Date().getTime() / 1000),
    decimal: 0,
    phaseNo: Math.round(Math.random() * 1000000000000),
    logo: "",
    linearStartAmount: "",
    csvFile: null,
    editId: "",
    isEdit: false,
    vestingsArr: [],
    vestingType: "linear",
    enableRefunds: false,
    refundStartDate: Math.round(new Date().getTime() / 1000),
    refundEndDate: Math.round(new Date().getTime() / 1000),
    idoContractAddress: "",
    contractHash: "",
    projectId: "",
    dumpId: "",
  });

  const { networkData, networkName } = useMemo(() => {
    const networkData = NETWORK_DATA.find((o) => o.suffix === state.network);
    return { networkData, networkName: networkData?.name };
  }, [state.network]);

  const contractVersionType = useMemo(() => {
    if (state.enableRefunds && networkName !== "BSC") {
      return CONTRACT_TYPES.AUTO_REFUNDS_CROSS_CHAIN;
    }

    return CONTRACT_TYPES.AUTO_REFUNDS;
  }, [state.enableRefunds, networkName]);

  const updateState = useCallback((newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  }, []);

  useEffect(() => {
    const path = window.location.pathname;
    let newPath = "";
    if (isCompleted) {
      newPath = path.replace("/dashboard/edit-claim-pool/", "");
    } else newPath = path.replace("/dashboard/edit-pending-claim-pool/", "");
    if (newPath && path.includes("edit")) callEditPoolDetails(newPath);

    connectMetamask();
  }, []);

  useEffect(() => {
    if (state.tokenAddress) getDecimals();
  }, [state.tokenAddress]);

  const callEditPoolDetails = async (id) => {
    const url = `claim/${
      isCompleted ? "single" : "get-dump"
    }/${id}?csvData=true`;

    const sendRequest = await services.Get(url);
    if (sendRequest.status === 200) {
      updateState({ isEdit: true, editId: id });
      const data = sendRequest.data.data;

      updateState({
        projectId: data._id,
        dumpId: isCompleted ? data.dumpId?._id : data._id,
        tokenAddress: data.tokenAddress,
        contractAddress: data.contractAddress,
        logo: data.logo,
        idoName: data.name,
        phaseNo: Math.round(Math.random() * 1000000000000),
        previousAmount: data.amount,
        totalAmount: data.amount,
        utcStartDate: data.timestamp,
        claimData: data.data?.length
          ? data.data
          : data.uploadData?.length
          ? data.uploadData
          : data.pendingData,
        utcEndDate: +data.endTime,
        linearStartAmount: data.startAmount,
        vestingType: data.vestingType,
        vestingsArr: data.vestings,
        refundStartDate: data.refundStartDate,
        refundEndDate: data.refundEndDate,
        idoContractAddress: data.idoContractAddress,
        startAmount: data.startAmount,
        enableRefunds: data.enableRefunds,
        refundStartDate: data.refundStartDate,
        refundEndDate: data.refundEndDate,
      });
    } else {
      console.log("err");
    }
  };

  const renderErrorToast = (text) => {
    return toaster(`${text || "Something went wrong."}`, "error");
  };

  const renderSuccessToast = (text) => {
    return toaster(`${text}`, "success");
  };

  const connectMetamask = async () => {
    try {
      const { isLoggedIn, accounts } = await web3Services.enableMetamask();

      if (isLoggedIn) {
        const chainId = await window.ethereum.request({
          method: "eth_chainId",
        });

        updateState({ network: chainId });
        setWeb3Data({ isLoggedIn, accounts });
        getNetworkId(chainId);

        window.ethereum.on("chainChanged", (chainId) => {
          if (chainId && CHAIN_ID_LIST.includes(chainId)) {
            updateState({ network: chainId });
            getNetworkId(chainId);
          } else {
            renderErrorToast("Please select valid network");
          }
        });
      }
    } catch (error) {
      console.error("Error connecting to metamask", error);
    }
  };

  const changeNetwork = async (val) => {
    let data = NETWORK_LIST[val];

    if (!data) {
      return renderErrorToast("Please select valid network");
    }

    await window.ethereum
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: data.chainId }],
      })
      .catch((error) => {
        renderErrorToast("Error while trying to connect to network");
        console.error({
          error: "Error while trying to connect to network",
          network: val,
          error,
        });
      });
  };

  const getNetworkId = async (val) => {
    try {
      const id = await window.ethereum.request({ method: "eth_chainId" });
      if (id !== val) {
        changeNetwork(val);
      }
    } catch (error) {
      return 1;
    }
  };

  const getDecimals = async () => {
    try {
      const tokenInstance = await getTokenInstance(state.tokenAddress);
      const decimal = await tokenInstance.methods.decimals().call();
      updateState({ decimal });
    } catch (error) {
      renderErrorToast("Please select valid token");
    }
  };

  const getNumberOfDecimals = (value) => {
    const parts = value.toString().split(".");
    return parts.length === 2 ? parts[1].length : 0;
  };

  const handleCheckAllowance = async (address, _contractAddress) => {
    console.log("Checking allowance", { address, _contractAddress });
    return new Promise(async (resolve, reject) => {
      try {
        const contractInstance = await getTokenInstance(state.tokenAddress);

        const checkAllowanceGiven = await contractInstance.methods
          .allowance(address, state.contractAddress)
          .call();

        const balance = await contractInstance.methods
          .balanceOf(web3Data.accounts[0])
          .call();

        const maxPrecision = 10; //max decimals to take into account
        const decimalsInAmount = getNumberOfDecimals(state.totalAmount);
        if (decimalsInAmount > maxPrecision) {
          renderErrorToast(
            "Error: the number of decimals on the CSV is too high, we can only accept 6 decimals."
          );
          return reject(false);
        }

        const tokenAmount = parseFixed(
          state.totalAmount.toString(),
          state.decimal
        ).sub(parseFixed(state.previousAmount.toString(), state.decimal));

        if (BigInt(balance) < tokenAmount.toBigInt()) {
          renderErrorToast("Error: Insufficient funds in your wallet");
          return reject(false);
        } else if (BigInt(checkAllowanceGiven) < tokenAmount.toBigInt()) {
          const gasPrice = web3.eth.getGasPrice();

          await contractInstance.methods
            .approve(state.contractAddress, tokenAmount)
            .send({
              from: web3Data.accounts[0],
              gasPrice: await gasPrice,
            });
          resolve(true);
        } else {
          resolve(true);
        }
      } catch (err) {
        reject(false);
        renderErrorToast(
          err.message || "Something went wrong while checking allowance."
        );
      }
    });
  };

  const addRecords = async (data) => {
    try {
      const sendRequest = await services.Post("/claim/add-dump", data);

      if (
        sendRequest.status !== 200 ||
        !sendRequest.data ||
        !sendRequest.data.waitToProceed
      ) {
        return renderErrorToast(
          `Something went wrong: ${
            sendRequest?.message || sendRequest.data?.message
          }`
        );
      }

      if (sendRequest.data.waitToProceed) {
        renderSuccessToast(
          "Users data is saved, proceeding with transactions."
        );
        linearWaitForMerkle(sendRequest.data.id);
      }
    } catch (err) {
      renderErrorToast(err.message);
    }
  };

  const doSummation = (num1, num2) => {
    return parseFloat(+num1 + +num2);
  };

  const separateData = (data) => {
    const addressArr = [];
    const valueArr = [];
    const startAmountArr = [];
    let totalSum = 0.0;

    const limit = data.length;

    for (let i = 0; i < limit; i++) {
      startAmountArr.push(
        parseFixed(state.linearStartAmount.toString(), state.decimal)
      );

      const eToken = (+data[i].eTokens).toFixed(6);

      totalSum = doSummation(totalSum, eToken);

      addressArr.push(data[i].walletAddress);

      valueArr.push(parseFixed(eToken.toString(), state.decimal));
    }

    totalSum = totalSum.toFixed(6);

    return { addressArr, valueArr, totalSum, startAmountArr };
  };

  const addDataToContract = async (
    previousAmount,
    totalAmount,
    vestingInfo
  ) => {
    const tokensToAdd = BigNumber.from(totalAmount).sub(previousAmount);

    console.log("Adding data to contract", {
      contractAddress: state.contractAddress,
      vestingInfo,
      startDate: state.utcStartDate,
      endDate: state.utcEndDate,
      tokensToAdd,
    });

    const params = [
      vestingInfo,
      state.utcStartDate,
      state.utcEndDate,
      tokensToAdd,
    ];

    return new Promise(async (resolve, reject) => {
      try {
        let hash = "";
        const contractInstance = await getContractInstanceAbi(
          state.contractAddress,
          contractVersionType
        );
        const gasPrice = web3.eth.getGasPrice();

        await contractInstance.methods
          .update(...params)
          .send({
            from: web3Data.accounts[0],
            gasPrice: await gasPrice,
          })
          .on("transactionHash", (_hash) => {
            hash = _hash;
            resolve(hash);
          })
          .on("receipt", async () => {
            // resolve(updateHash);
          });
      } catch (err) {
        console.error(`Error while calling "update" function on contract`, err);
        reject(false);
      }
    });
  };

  const addLayerZeroConfig = async (selectedConfig) => {
    const params = [
      {
        dstEID: LAYER_ZERO_CONFIG.BSC.layerZeroDestinationEID,
        dstAddress: state.idoContractAddress,
        sendLibrary: selectedConfig.sendLibraryULNAddress,
        receiveLibrary: selectedConfig.receiveLibraryULNAddress,
      },
    ];

    return new Promise(async (resolve, reject) => {
      try {
        let updateHash = "";
        const contractInstance = await getContractInstanceAbi(
          state.contractAddress,
          contractVersionType
        );

        await contractInstance.methods
          .updateLzConfig(...params)
          .send({
            from: web3Data.accounts[0],
            gas: 600000,
            gasPrice: await web3.eth.getGasPrice(),
          })
          .on("transactionHash", (_hash) => {
            updateHash = _hash;
          })
          .on("receipt", async () => {
            resolve(updateHash);
          });
      } catch (err) {
        console.log(err);
        reject(false);
      }
    });
  };

  // const addRefundDataToContract = async (startDate, endDate) => {
  //   console.log("Adding refund data to contract", { startDate, endDate });
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let refundHash = "";
  //       const contractInstance = await getContractInstanceAbi(
  //         state.contractAddress
  //       );

  //       await contractInstance.methods
  //         .setRefundPeriod(startDate, endDate)
  //         .send({
  //           from: web3Data.accounts[0],
  //           gasPrice: await web3.eth.getGasPrice(),
  //         })
  //         .on("transactionHash", (_hash) => {
  //           refundHash = _hash;
  //         })
  //         .on("receipt", async () => {
  //           resolve(refundHash);
  //         });
  //     } catch (err) {
  //       console.error(
  //         `Error while calling "setRefundPeriod" function on contract`,
  //         err
  //       );
  //       reject(false);
  //     }
  //   });
  // };

  const getAddedData = async (id) => {
    const sendRequest = await services.Get(`/claim/single/${id}`);
    if (sendRequest.status === 200) {
      return sendRequest?.data;
    }
  };

  const linearWaitForMerkle = async (id, amountOfRetries = 0) => {
    if (!id) {
      renderErrorToast("Something went wrong. Please try again later.");
      console.error("No ID returned from the add-dump call");
      return false;
    }

    if (amountOfRetries > 20) {
      renderErrorToast("Something went wrong. Please try again later.");
      console.error(
        "It took longer than 6 tries to get merkle data. Check backend logs. (10 min timeout)"
      );
      return false;
    }

    const data = await getAddedData(id);

    if (!data?.data?.vestingInfo) {
      setTimeout(() => {
        linearWaitForMerkle(id, amountOfRetries + 1);
      }, HALF_MINUTE_IN_MILLIS);
    } else {
      updateState({ editId: id });

      const checkAllowance = await handleCheckAllowance(
        web3Data.accounts[0],
        state.contractAddress
      );

      console.log("Linear Merkle Received, proceeding with transactions.", {
        data: data.data.data,
        startDate: state.utcStartDate,
        id,
        vestingInfo: data.data.vestingInfo,
      });

      if (checkAllowance) {
        recursiveCalls(data.data.data, id, data.data.vestingInfo);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessingTransaction(true);

    var data = new FormData();

    data.append("csv", state.csvFile);
    data.append("contractAddress", state.contractAddress);
    data.append("tokenAddress", state.tokenAddress);
    data.append("tokenDecimals", state.decimal);
    data.append("networkName", networkData.fullName);
    data.append("networkSymbol", networkData.symbol);
    data.append("networkId", networkData.suffix);
    data.append("amount", state.totalAmount);
    data.append("name", state.idoName);
    data.append("phaseNo", state.phaseNo);
    data.append("logo", state.logo);
    data.append("timestamp", state.utcStartDate);
    data.append("vestingType", state.vestingType);
    data.append("endTime", state.utcEndDate);
    data.append("startAmount", state.linearStartAmount);
    data.append("vestings", JSON.stringify(state.vestingsArr));
    data.append("enableRefunds", state.enableRefunds);
    if (state.enableRefunds) {
      data.append("refundStartDate", state.refundStartDate);
      data.append("refundEndDate", state.refundEndDate);
      data.append("idoContractAddress", state.idoContractAddress);
    }

    await addRecords(data);
  };

  const migrateData = async (id) => {
    const data = await getAddedData(id);

    const callApi = isSWORLD ? axiosSeedworld : axiosSeedify;

    console.log("Migrating data", callApi);

    await callApi
      .post("/claims/create", data.data)
      .then(() => {
        renderSuccessToast(
          `Data was migrated successfully to ${
            isSWORLD ? "Seedworld" : "Seedify"
          }.`
        );
      })
      .catch((error) => {
        renderErrorToast("Error while migrating pool.");
        console.error(error);
      });
  };

  const recursiveCalls = async (addeddata, id, vestingInfo) => {
    console.log("Calling RECURSIVE CALLS", { addeddata, id, vestingInfo });
    setIsProcessingTransaction(true);

    try {
      const usersSeparatedData = separateData(addeddata);

      if (
        state.enableRefunds &&
        contractVersionType === "auto-refunds-cross-chain" &&
        state.updateLzConfig === true
      ) {
        const selectedConfig = LAYER_ZERO_CONFIG[networkName];

        if (!selectedConfig) {
          return renderErrorToast(
            "Layer zero config not found for this network, please try again."
          );
        }

        const hash = await addLayerZeroConfig(selectedConfig);

        if (!hash) {
          return renderErrorToast(
            "Error while adding layer zero config to contract, please try again."
          );
        }
      }

      const hash = await addDataToContract(
        parseFixed(state.previousAmount.toString(), state.decimal),
        parseFixed(usersSeparatedData.totalSum.toString(), state.decimal),
        vestingInfo
      );

      console.log("Data added to contract, hash: ", hash);

      if (hash) {
        await updateTransactionHash(hash, id);

        // if (state.enableRefunds) {
        //   const refundHash = await addRefundDataToContract(
        //     state.refundStartDate,
        //     state.refundEndDate
        //   );

        //   if (!refundHash) {
        //     renderErrorToast(
        //       "Error while adding refund data to contract, please try again."
        //     );
        //   }
        // }

        await migrateData(id);
      }
    } catch (err) {
      console.log(err, err.message);
    }

    setIsProcessingTransaction(false);
  };

  const updateTransactionHash = async (hash, dumpId) => {
    const data = JSON.stringify({
      transactionHash: hash,
      dumpId: dumpId,
      numberOfRecords: state.claimData.length,
    });

    try {
      setIsProcessingTransaction(true);

      const sendRequest = await services.Post("/claim/update-dump", data);

      if (sendRequest.status === 200) {
        if (state.vestingsArr.length) {
          return renderSuccessToast("Please proceed with updating tokens");
        }
        if (sendRequest.data.data.data.length) {
          recursiveCalls(
            sendRequest.data.data.data,
            sendRequest.data.data._id,
            sendRequest.data.data.vestingInfo
          );
        } else {
          renderSuccessToast("All users are updated successfully.");
        }
      }
    } catch (err) {
      renderErrorToast(err.message);
    } finally {
      setIsProcessingTransaction(false);
    }
  };

  const deployContract = async () => {
    try {
      if (!state.tokenAddress) {
        return renderErrorToast("Please enter the Token Address first.");
      }

      if (state.enableRefunds && !state.idoContractAddress) {
        return renderErrorToast("Please enter the IDO Contract Address first.");
      }

      setIsCreatingContract(true);

      const abi = getContractAbi(contractVersionType);
      const bytecode = getContractByteCode(contractVersionType);

      if (!abi || !bytecode) {
        return renderErrorToast("Contract not found");
      }

      let deploy_contract = new web3.eth.Contract(abi);

      const args = [state.tokenAddress];

      if (state.enableRefunds) {
        if (contractVersionType === "auto-refunds-cross-chain") {
          const layerZeroSrcEndpoint =
            LAYER_ZERO_CONFIG.BSC.layerZeroSrcEndpoint;
          args.push(layerZeroSrcEndpoint);
        } else {
          args.push(state.idoContractAddress);
        }
      } else {
        args.push(ZERO_ADDRESS);
      }

      const gasEstimate = deploy_contract
        .deploy({
          data: bytecode,
          arguments: args,
        })
        .estimateGas({ from: web3Data.accounts[0] });

      const gasPrice = web3.eth.getGasPrice();

      console.log("CREATING LINEAR CONTRACT", args);

      await deploy_contract
        .deploy({
          data: bytecode,
          arguments: args,
        })
        .send({
          from: web3Data.accounts[0],
          gas: await gasEstimate,
          gasPrice: await gasPrice,
        })
        .on("error", (error) => {
          console.error(error);
          return false;
        })
        .on("transactionHash", (transactionHash) => {
          updateState({
            contractHash: transactionHash,
          });
          renderSuccessToast("Transaction Processing...", false);
        })
        .on("receipt", (receipt) => {
          renderSuccessToast(
            "Linear Contract deployed successfully. Submit the data and proceed with updating the users."
          );
          updateState({
            contractAddress: receipt.contractAddress,
            contractHash: receipt.transactionHash,
          });

          return true;
        });
    } catch (err) {
      console.error(err);
      renderErrorToast("Error while deploying contract");
    }

    setIsCreatingContract(false);
  };

  const getTotalAmount = (list) => {
    let totalSum = 0;
    let maxDecimals = 0;
    for (let i = 0; i < list.length; i++) {
      const decimals = getNumberOfDecimals(list[i].eTokens);
      if (decimals > 6) {
        throw new Error(
          "Maximum number of decimals allowed in the CSV file is 6"
        );
      }
      if (decimals > maxDecimals) {
        maxDecimals = decimals;
      }

      totalSum = doSummation(totalSum, Number(+list[i].eTokens).toFixed(6));
      console.log(totalSum, list[i].eTokens);
    }

    return totalSum.toFixed(maxDecimals);
  };

  const { isEdit } = state;

  return (
    <Page title="Seedify | Add Claim Pools">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Claim Token
          </Typography>
          <Button
            variant="contained"
            startIcon={
              web3Data.isLoggedIn ? null : <Icon icon={link2Outline} />
            }
          >
            {web3Data.isLoggedIn
              ? compactAddress(web3Data.accounts[0])
              : "Connect Wallet"}
          </Button>
        </Stack>

        <Card
          as="form"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            padding: "20px",
          }}
          onSubmit={handleSubmit}
        >
          <Stack spacing={2} direction="row">
            <CustomSwitch>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={state.enableRefunds}
                  onChange={() =>
                    updateState({ enableRefunds: !state.enableRefunds })
                  }
                />
                <span className="slider round" />
              </label>
            </CustomSwitch>
            <h4>Enable Refunds</h4>
          </Stack>

          <TextField
            disabled={isEdit}
            autoComplete="ido"
            value={state.idoName}
            onChange={(e) => {
              updateState({ idoName: e.target.value });
            }}
            type="text"
            label="Ido Name"
            required
          />
          <Stack spacing={2} direction="row">
            <FormControl style={{ width: "50%" }}>
              <InputLabel id="network-select-label">Select Network</InputLabel>
              <Select
                disabled={isEdit}
                labelId="network-select-label"
                id="network-select"
                label="Select Network"
                value={state.network}
                onChange={(e) => {
                  updateState({ network: e.target.value });
                  getNetworkId(e.target.value);
                }}
                required
              >
                <MenuItem value=""></MenuItem>
                {NETWORK_DATA.map((network) => (
                  <MenuItem key={network.suffix} value={network.suffix}>
                    {network.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl style={{ width: "50%" }}>
              <InputLabel>Select Vesting Type</InputLabel>
              <Select
                disabled={isEdit}
                value={state.vestingType}
                label="Select Vesting type"
                onChange={(e) => {
                  updateState({
                    vestingType: e.target.value,
                  });
                }}
                required
              >
                <MenuItem value="linear">Linear</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          <Stack spacing={2} direction="row">
            <TextField
              disabled={isEdit}
              fullWidth
              type="text"
              value={state.tokenAddress}
              label="Token Address"
              onChange={(e) => {
                updateState({ tokenAddress: e.target.value });
              }}
              required
            />

            <TextField
              disabled
              fullWidth
              type="number"
              value={state.decimal}
              label="Decimal points"
              required
            />
          </Stack>

          {state.enableRefunds ? (
            <TextField
              fullWidth
              type="text"
              value={state.idoContractAddress}
              label="IDO Contract Address"
              onChange={(e) =>
                updateState({
                  idoContractAddress: e.target.value,
                  updateLzConfig: true,
                })
              }
            />
          ) : null}

          <Stack spacing={2} direction="row">
            <ParentField style={{ width: "70%" }}>
              <TextField
                fullWidth
                autoComplete="Contract Address"
                type="text"
                value={state.contractAddress}
                label="Contract Address"
                disabled={state.network === "" || isEdit}
                onChange={(e) => {
                  updateState({ contractAddress: e.target.value });
                }}
                required
              />

              <LoadingButton
                disabled={!state.tokenAddress}
                type="button"
                size="small"
                variant="contained"
                loading={!state.contractAddress && isCreatingContract}
                onClick={() => deployContract()}
              >
                Deploy Linear Contract
              </LoadingButton>
            </ParentField>

            <ParentField style={{ width: "30%" }}>
              <TextField
                disabled
                type="number"
                fullWidth
                value={state.phaseNo}
                onChange={(e) => {
                  updateState({ phaseNo: e.target.value });
                }}
                label="Phase No"
                required
              />
              <Button
                size="small"
                variant="contained"
                onClick={() =>
                  updateState({
                    phaseNo: Math.round(Math.random() * 10000000000000000),
                  })
                }
              >
                Generate new
              </Button>
            </ParentField>
          </Stack>

          <Stack spacing={2} direction="row">
            <TextField
              fullWidth
              type="file"
              id="claimFile"
              accept=".csv"
              onChange={async (e) => {
                updateState({ csvFile: e.target.files[0] });
                const list = await readFile(e);

                const totalAmount = getTotalAmount(list);

                updateState({
                  claimData: list,
                  totalAmount,
                });
              }}
              required={!isEdit}
            />

            <TextField
              disabled
              fullWidth
              type="number"
              label="Total Rewards"
              value={state.totalAmount}
              required
            />

            <TextField
              disabled={isEdit}
              fullWidth
              type="number"
              label="Start Amount"
              value={state.linearStartAmount}
              onChange={(e) => {
                updateState({ linearStartAmount: e.target.value });
              }}
              required
            />
          </Stack>

          <TextField
            disabled={isEdit}
            fullWidth
            type="text"
            label="Logo Url"
            value={state.logo}
            onChange={(e) => {
              updateState({ logo: e.target.value });
            }}
            required
          />

          <Stack spacing={4} direction="row" justifyContent="space-between">
            <Stack spacing={2} direction="row">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  disabled={isEdit}
                  renderInput={(props) => <TextField {...props} fullWidth />}
                  label="Select Start Date (UTC)"
                  value={dayjs(utcToLocalTimeStamp(+state.utcStartDate * 1000))}
                  onChange={(date) => {
                    const now_utc = Date.UTC(
                      date.year(),
                      date.month(),
                      date.date(),
                      date.hour(),
                      date.minute(),
                      date.second()
                    );

                    updateState({
                      utcStartDate: now_utc / 1000,
                    });
                  }}
                  required
                />

                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Select End Date (UTC)"
                  value={dayjs(utcToLocalTimeStamp(+state.utcEndDate * 1000))}
                  onChange={(date) => {
                    const now_utc = Date.UTC(
                      date.year(),
                      date.month(),
                      date.date(),
                      date.hour(),
                      date.minute(),
                      date.second()
                    );

                    updateState({
                      utcEndDate: now_utc / 1000,
                    });
                  }}
                  required
                />
              </LocalizationProvider>
            </Stack>

            <Stack
              direction="row"
              paddingRight="6px"
              spacing={2}
              alignItems="center"
            >
              <CustomSwitch>
                <label className="switch">
                  <input
                    type="checkbox"
                    value={isSWORLD}
                    onChange={() => setIsSWORLD(!isSWORLD)}
                  />
                  <span className="slider round" />
                </label>
              </CustomSwitch>
              <h4>SWORLD Drop</h4>
            </Stack>
          </Stack>

          {state.enableRefunds ? (
            <Stack spacing={2} direction="row">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Refund Start Date (UTC)"
                  value={dayjs(
                    utcToLocalTimeStamp(+state.refundStartDate * 1000)
                  )}
                  onChange={(date) => {
                    const now_utc = Date.UTC(
                      date.year(),
                      date.month(),
                      date.date(),
                      date.hour(),
                      date.minute(),
                      date.second()
                    );
                    updateState({
                      refundStartDate: now_utc / 1000,
                    });
                  }}
                  required
                />
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Refund End Date (UTC)"
                  value={dayjs(
                    utcToLocalTimeStamp(+state.refundEndDate * 1000)
                  )}
                  onChange={(newValue) => {
                    var date = newValue;
                    var now_utc = Date.UTC(
                      date.year(),
                      date.month(),
                      date.date(),
                      date.hour(),
                      date.minute(),
                      date.second()
                    );
                    updateState({
                      refundEndDate: now_utc / 1000,
                    });
                  }}
                  required
                />
              </LocalizationProvider>
            </Stack>
          ) : null}

          <LoadingButton
            fullWidth
            type="submit"
            size="large"
            variant="contained"
            loading={isProcessingTransaction}
            disabled={!state.contractAddress || isCreatingContract}
          >
            Submit
          </LoadingButton>
        </Card>
      </Container>
    </Page>
  );
};

export default AddClaimPools;

const ParentField = styled.div`
  position: relative;
  button {
    position: absolute;
    right: 10px;
    top: calc(50% - 20px);
    color: #fff;
    background-color: #5bb3fc;
    padding: 10px 22px;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    font-family: Public Sans, sans-serif;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
`;

const CustomSwitch = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slider {
    background-color: #2196f3;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
`;
