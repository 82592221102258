import PropTypes from 'prop-types';
import LogoImg from '../assets/logo-black.png';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return <img src={LogoImg} alt="Seedify Fund Snapshot" />;
}
