import { useState } from "react";
import { Stack, Typography, Card, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Page from "src/components/Page";
import { toaster } from "src/utils/toast";
import { axiosSeedify } from "src/config";

const AddSuiPools = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [data, setData] = useState({
    contractAddress: "",
    globalStateAddress: "",
    name: "",
    logo: "",
  });

  const renderErrorToast = (text) => {
    setShowLoader(false);
    return toaster(`${text || "Something went wrong."}`, "error");
  };

  const renderSuccessToast = (text) => {
    setShowLoader(false);
    return toaster(`${text}`, "success");
  };

  const newSubmit = async (e) => {
    e.preventDefault();

    setShowLoader(true);

    await axiosSeedify
      .post("/sui/contracts", data)
      .then(() => {
        renderSuccessToast("Data was created successfully.");
      })
      .catch((error) => {
        renderErrorToast("Error while storing contract data.");
        console.error(error);
      });

    // TODO: redirect back
  };

  return (
    <Page title="Add Sui Pool | Seedify Fund Snapshot">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Sui Claim Token
        </Typography>
      </Stack>
      <Card style={{ padding: "20px" }}>
        <form onSubmit={newSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              type="text"
              value={data.contractAddress}
              label="Contract Address"
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  contractAddress: e.target.value,
                }));
              }}
              required
            />

            <TextField
              fullWidth
              type="text"
              value={data.globalStateAddress}
              label="Global State Address"
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  globalStateAddress: e.target.value,
                }));
              }}
              required
            />

            <TextField
              fullWidth
              value={data.name}
              onChange={(e) => {
                setData((prev) => ({ ...prev, name: e.target.value }));
              }}
              type="text"
              label="IDO Name *This will overwrite vesting name"
            />

            <TextField
              fullWidth
              type="text"
              label="Logo Url"
              value={data.logo}
              onChange={(e) => {
                setData((prev) => ({ ...prev, logo: e.target.value }));
              }}
            />
          </Stack>

          <LoadingButton
            fullWidth
            disabled={!data.contractAddress}
            size="large"
            type="submit"
            variant="contained"
            loading={showLoader}
            style={{ marginTop: "25px" }}
          >
            Submit
          </LoadingButton>
        </form>
      </Card>
    </Page>
  );
};

export default AddSuiPools;
