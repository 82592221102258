const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export function TimeStampToDateString(val) {
  const dateString = new Date(Number(val) * 1000);
  var year = dateString.getUTCFullYear();
  // month as 2 digits (MM)
  var month = dateString.getUTCMonth();
  // date as 2 digits (DD)
  var date = ("0" + dateString.getUTCDate()).slice(-2);
  var hrs = ("0" + dateString.getUTCHours()).slice(-2);
  var min = ("0" + dateString.getUTCMinutes()).slice(-2);
  var sec = ("0" + dateString.getUTCSeconds()).slice(-2);
  let d = `${year}-${months[month]}-${date} ${hrs}:${min}:${sec} UTC`;
  return d;
}
export function utcToLocalTimeStamp(timestamp) {
  const date = new Date(timestamp);
  var newTimeStamp = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  return newTimeStamp;
}

export function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function compactAddress(address) {
  const newAddress = address;
  if (address) {
    const res =
      newAddress.substring(0, 4) +
      "..." +
      newAddress.substring(newAddress.length - 4, newAddress.length);
    return res;
  }
}
