import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Link, Drawer, Typography, Avatar, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
import sidebarConfig from "./SidebarConfig";
import account from "../../_mocks_/account";
import { compactAddress } from "src/contractData/function";
import { web3 } from "src/web3";
import { Icon } from "@iconify/react";
import link2Outline from "@iconify/icons-eva/link-2-outline";
import styledComponent from "styled-components";
import MetamaskImg from "../../assets/mm.png";
import { services } from "../../services";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

const MetamaskBTN = styledComponent.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  padding: 10px 30px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  img {
    width: 45px;
    margin-right: 10px;
  }
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "30px",
  boxShadow: 24,
  p: 4,
};
// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [web3Data, setWeb3Data] = useState({
    isLoggedIn: false,
    accounts: [],
  });

  const checkIsConnected = () => {
    web3.eth.getAccounts(function (err, accounts) {
      if (err || accounts.length === 0) {
        setWeb3Data({
          isLoggedIn: false,
          accounts: [],
        });
      } else {
        setWeb3Data({
          isLoggedIn: true,
          accounts: accounts,
        });
      }
    });
  };

  const connectMetamask = async () => {
    try {
      const getAcountDetails = await services.enableMetamask();
      setWeb3Data(getAcountDetails);
      handleClose();
    } catch (err) {
      console.log("error is:", err);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    checkIsConnected();
  }, []);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {account.displayName}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {account.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
        <Button
          fullWidth
          variant="contained"
          startIcon={web3Data.isLoggedIn ? <Icon icon={link2Outline} /> : null}
          onClick={() => {
            if (!web3Data.isLoggedIn) handleOpen();
          }}
        >
          {web3Data.isLoggedIn
            ? compactAddress(web3Data.accounts[0])
            : "Connect Wallet"}
        </Button>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <h3 style={{ textAlign: "center" }}>
              Please connect to your Wallet
            </h3>
            <MetamaskBTN onClick={connectMetamask}>
              <img src={MetamaskImg} alt="" /> Connect Metamask
            </MetamaskBTN>
          </Typography>
        </Box>
      </Modal>
    </RootStyle>
  );
}
