import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
// import trash2Fill from '@iconify/icons-eva/trash-2-fill';
// import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 280,
  margin: theme.spacing(0, 2, 0, 0),
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterAddress: PropTypes.string,
  onFilterAddress: PropTypes.func,
  handleKeyDown: PropTypes.func,
  onFilterEmail: PropTypes.func,
  filterEmail: PropTypes.string,
};

export default function UserListToolbar({
  numSelected,
  filterAddress,
  onFilterAddress,
  handleKeyDown,
  filterEmail,
  onFilterEmail,
  country,
  setSelectedCountry,
  selectedCountry,
}) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component='div' variant='subtitle1'>
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterAddress}
          onChange={onFilterAddress}
          onKeyDown={handleKeyDown}
          placeholder='Search by Wallet Address...'
          startAdornment={
            <InputAdornment position='start'>
              <Box
                component={Icon}
                icon={searchFill}
                sx={{ color: 'text.disabled' }}
              />
            </InputAdornment>
          }
        />
      )}
      {numSelected > 0 ? (
        <Typography component='div' variant='subtitle1'>
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterEmail}
          onChange={onFilterEmail}
          onKeyDown={handleKeyDown}
          placeholder='Search by Email ...'
          startAdornment={
            <InputAdornment position='start'>
              <Box
                component={Icon}
                icon={searchFill}
                sx={{ color: 'text.disabled' }}
              />
            </InputAdornment>
          }
        />
      )}

      {/* <Stack spacing={3}> */}
      <FormControl fullWidth>
        <InputLabel id='demo-simple-select-label'>Search by country</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value=''
          label='Select Country'
          onChange={(e) => {
            setSelectedCountry(e.target.value);
          }}
          value={selectedCountry}
          required
        >
          <MenuItem value={''}></MenuItem>
          {country.map((country) => (
            <MenuItem value={`${country}`}>{country}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* </Stack> */}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <Icon icon={roundFilterList} />
          </IconButton>
        </Tooltip>
      )} */}
    </RootStyle>
  );
}
