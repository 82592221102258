import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
} from "@mui/material";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { UserListHead } from "../components/_dashboard/user";
import { axiosSeedify } from "src/config";
import { toaster } from "../utils/toast";
import { compactAddress } from "src/contractData/function";
import copy from "copy-to-clipboard";
import copyOutline from "@iconify/icons-eva/copy-outline";
import { Icon } from "@iconify/react";

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "contractAddress", label: "Contract Address", alignRight: false },
  {
    id: "globalStateAddress",
    label: "Global State Address",
    alignRight: false,
  },
  { id: "logo", label: "Logo", alignRight: false },
];

const copyToClipboard = (address) => {
  copy(address);
  return toaster(`${"Copied!"}`, "success");
};

const ManageSuiContracts = () => {
  const [contracts, setContracts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchContracts();
  }, []);

  const fetchContracts = async () => {
    setIsLoading(true);

    try {
      const response = await axiosSeedify.get("/sui/contracts");

      if (response.status === 200) {
        setContracts(response.data.data);
      }
    } catch (error) {
      toaster("Failed to fetch contracts", "error");
    }

    setIsLoading(false);
  };

  const isContractsListEmpty = contracts.length === 0;

  return (
    <Page title="Manage Feature Flags | Seedify">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Manage Sui Contracts
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={contracts.length}
                />
                <TableBody>
                  {contracts.map((row) => {
                    const {
                      _id,
                      contractAddress,
                      globalStateAddress,
                      name,
                      logo,
                    } = row;

                    return (
                      <TableRow hover key={_id} tabIndex={-1}>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell
                          align="left"
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                        >
                          {compactAddress(contractAddress)}
                          <span
                            style={{
                              position: "relative",
                              top: "4px",
                              cursor: "pointer",
                            }}
                          >
                            <Icon
                              icon={copyOutline}
                              onClick={() => copyToClipboard(contractAddress)}
                              height={18}
                              width={18}
                            />
                          </span>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                        >
                          {compactAddress(globalStateAddress)}
                          <span
                            style={{
                              position: "relative",
                              top: "4px",
                              cursor: "pointer",
                            }}
                          >
                            <Icon
                              icon={copyOutline}
                              onClick={() =>
                                copyToClipboard(globalStateAddress)
                              }
                              height={18}
                              width={18}
                            />
                          </span>
                        </TableCell>
                        <TableCell align="left">{logo}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isContractsListEmpty && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Typography variant="body1">
                          No contracts have been added yet.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
};

export default ManageSuiContracts;
