import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Modal,
  Box,
  Switch,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { UserListHead } from "../components/_dashboard/user";
import { axiosSeedify } from "src/config";
import { toaster } from "../utils/toast";

const TABLE_HEAD = [
  { id: "key", label: "Key", alignRight: false },
  { id: "value", label: "Value", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "actions", label: "Actions", alignRight: false },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

export default function ManageFeatureFlags() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("key");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [featureFlags, setFeatureFlags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalMode, setModalMode] = useState("create");
  const [currentFlag, setCurrentFlag] = useState({
    key: "",
    value: "",
    description: "",
  });

  useEffect(() => {
    fetchFeatureFlags();
  }, []);

  const fetchFeatureFlags = async () => {
    setIsLoading(true);
    try {
      const response = await axiosSeedify.get("/feature-flags");
      if (response.status === 200) {
        setFeatureFlags(response.data.featureFlags);
      }
    } catch (error) {
      toaster("Failed to fetch feature flags", "error");
    }
    setIsLoading(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = (
    mode,
    flag = { key: "", value: "", description: "" }
  ) => {
    setModalMode(mode);
    setCurrentFlag(flag);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentFlag({ key: "", value: "", description: "" });
  };

  const handleInputChange = (e) => {
    const value = e.target.name === "value" ? e.target.checked : e.target.value;
    setCurrentFlag({ ...currentFlag, [e.target.name]: value });
  };

  const handleSubmit = async () => {
    try {
      if (modalMode === "create") {
        await axiosSeedify.post("/feature-flags/create", currentFlag);
        toaster("Feature flag created successfully", "success");
      } else {
        await axiosSeedify.put(
          `/feature-flags/${currentFlag.key}`,
          currentFlag
        );
        toaster("Feature flag updated successfully", "success");
      }
      handleCloseModal();
      fetchFeatureFlags();
    } catch (error) {
      toaster("Failed to save feature flag", "error");
    }
  };

  const handleDelete = async (key) => {
    try {
      await axiosSeedify.delete(`/feature-flags/${key}`);
      toaster("Feature flag deleted successfully", "success");
      fetchFeatureFlags();
    } catch (error) {
      toaster("Failed to delete feature flag", "error");
    }
  };

  const handleToggleValue = async (key, currentValue) => {
    try {
      const updatedFlag = { value: !currentValue };
      await axiosSeedify.put(`/feature-flags/${key}`, updatedFlag);
      toaster("Feature flag updated successfully", "success");
      fetchFeatureFlags();
    } catch (error) {
      toaster("Failed to update feature flag", "error");
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - featureFlags.length) : 0;

  const isFeatureFlagListEmpty = featureFlags.length === 0;

  return (
    <Page title="Manage Feature Flags | Seedify">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Manage Feature Flags
          </Typography>
          <Button variant="contained" onClick={() => handleOpenModal("create")}>
            Create New Feature Flag
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={featureFlags.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {featureFlags
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { key, value, description } = row;

                      return (
                        <TableRow hover key={key} tabIndex={-1}>
                          <TableCell align="left">{key}</TableCell>
                          <TableCell align="left">
                            <Switch
                              checked={value}
                              onChange={() => handleToggleValue(key, value)}
                            />
                          </TableCell>
                          <TableCell align="left">{description}</TableCell>
                          <TableCell align="left">
                            <Button
                              onClick={() => handleOpenModal("edit", row)}
                            >
                              Edit
                            </Button>
                            <Button onClick={() => handleDelete(key)}>
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isFeatureFlagListEmpty && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Typography variant="body1">
                          No feature flags have been added yet.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={featureFlags.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
            {modalMode === "create"
              ? "Create New Feature Flag"
              : "Edit Feature Flag"}
          </Typography>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Key"
              name="key"
              value={currentFlag.key}
              onChange={handleInputChange}
              disabled={modalMode === "edit"}
            />
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>Value:</Typography>
              <Switch
                name="value"
                checked={currentFlag.value}
                onChange={handleInputChange}
              />
            </Stack>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={currentFlag.description}
              onChange={handleInputChange}
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
              {modalMode === "create" ? "Create" : "Update"}
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </Page>
  );
}
