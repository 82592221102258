import { axios } from "../config";
import { toaster } from "../utils/toast";

export const backendServices = {
  Get,
  Post,
  Put,
};

async function Post(url, params) {
  const header = {
    "api-key":
      "da3f89789b06fa0c5c3be65e5e18a7fafdda6bcdb51db9fe2b821c634c042405",
    "content-type": "application/json",
    "x-auth-token": localStorage.getItem("token")
      ? localStorage.getItem("token")
      : null,
  };
  try {
    const response = await axios.post(url, params, { headers: header });
    return response;
  } catch (err) {
    console.log("error is:", err);
    if (err.response) {
      if (err.response.status === 401) {
        // const history = useNavigate();
        // history('/login');
        localStorage.removeItem("token");
      }
      return toaster(
        `${err.response ? err.response.data.message : "Something went wrong"}`,
        "error"
      );
    } else {
      return toaster(
        `${err.response ? err.response.data.message : "Something went wrong"}`,
        "error"
      );
    }
  }
}

async function Get(url) {
  const header = {
    "api-key":
      "da3f89789b06fa0c5c3be65e5e18a7fafdda6bcdb51db9fe2b821c634c042405",
    "content-type": "application/json",
    "x-auth-token": localStorage.getItem("token")
      ? localStorage.getItem("token")
      : null,
  };
  try {
    const response = await axios.get(url, { headers: header });
    return response;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 401) {
        console.log("1", err.response);
        window.location.replace("/");
        // history.push('/login');
        localStorage.removeItem("token");
      }
      console.log(err);
      return toaster(
        `${err.response ? err.response.data.message : "Something went wrong"}`,
        "error"
      );
    } else {
      console.log(err);
      return toaster(
        `${err.response ? err.response.data.message : "Something went wrong"}`,
        "error"
      );
    }
  }
}

async function Put(url, parameters) {
  //   const history = useNavigate();
  const header = {
    "content-type": "application/json",
    "x-auth-token": localStorage.getItem("token")
      ? localStorage.getItem("token")
      : null,
  };
  try {
    const response = await axios.put(url, parameters, { headers: header });
    return response;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 401) {
        // history.push('/login');
        localStorage.removeItem("token");
      }
      return toaster(
        `${err.response ? err.response.data.message : "Something went wrong"}`,
        "error"
      );
    } else {
      return toaster(
        `${err.response ? err.response.data.message : "Something went wrong"}`,
        "error"
      );
    }
  }
}
