const environment =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "prod"
    : process.env.REACT_APP_ENVIRONMENT === "staging"
    ? "staging"
    : "dev";

const config = require(`../config/config.${environment}`).default;

const TESTNET_LAYER_ZERO_CONFIG = {
  BSC: {
    layerZeroSrcEndpoint: "0x6EDCE65403992e310A62460808c4b910D972f10f",
    layerZeroDestinationEID: "40102",
    sendLibraryULNAddress: "0x55f16c442907e86D764AFdc2a07C2de3BdAc8BB7",
    receiveLibraryULNAddress: "0x188d4bbCeD671A7aA2b5055937F79510A32e9683",
  },
  ETH: {
    layerZeroSrcEndpoint: "0x6EDCE65403992e310A62460808c4b910D972f10f",
    layerZeroDestinationEID: "40161",
    sendLibraryULNAddress: "0xcc1ae8Cf5D3904Cef3360A9532B477529b177cCE",
    receiveLibraryULNAddress: "0xdAf00F5eE2158dD58E0d3857851c432E34A3A851",
  },
  POLYGON: {
    layerZeroSrcEndpoint: "0x6EDCE65403992e310A62460808c4b910D972f10f",
    layerZeroDestinationEID: "40267",
    sendLibraryULNAddress: "0x1d186C560281B8F1AF831957ED5047fD3AB902F9",
    receiveLibraryULNAddress: "0x53fd4C4fBBd53F6bC58CaE6704b92dB1f360A648",
  },
  ARBITRUM: {
    layerZeroSrcEndpoint: "0x6EDCE65403992e310A62460808c4b910D972f10f",
    layerZeroDestinationEID: "40231",
    sendLibraryULNAddress: "0x4f7cd4DA19ABB31b0eC98b9066B9e857B1bf9C0E",
    receiveLibraryULNAddress: "0x75Db67CDab2824970131D5aa9CECfC9F69c69636",
  },
  AVAX: {
    layerZeroSrcEndpoint: "0x6EDCE65403992e310A62460808c4b910D972f10f",
    layerZeroDestinationEID: "40106",
    sendLibraryULNAddress: "0x69BF5f48d2072DfeBc670A1D19dff91D0F4E8170",
    receiveLibraryULNAddress: "0x819F0FAF2cb1Fba15b9cB24c9A2BDaDb0f895daf",
  },
  BASE: {
    layerZeroSrcEndpoint: "0x6EDCE65403992e310A62460808c4b910D972f10f",
    layerZeroDestinationEID: "40245",
    sendLibraryULNAddress: "0xC1868e054425D378095A003EcbA3823a5D0135C9",
    receiveLibraryULNAddress: "0x12523de19dc41c91F7d2093E0CFbB76b17012C8d",
  },
};

const MAINNET_LAYER_ZERO_CONFIG = {
  BSC: {
    layerZeroSrcEndpoint: "0x1a44076050125825900e736c501f859c50fE728c",
    layerZeroDestinationEID: "30102",
    sendLibraryULNAddress: "0x9F8C645f2D0b2159767Bd6E0839DE4BE49e823DE",
    receiveLibraryULNAddress: "0xB217266c3A98C8B2709Ee26836C98cf12f6cCEC1",
  },
  ETH: {
    layerZeroSrcEndpoint: "0x1a44076050125825900e736c501f859c50fE728c",
    layerZeroDestinationEID: "30101",
    sendLibraryULNAddress: "0xbB2Ea70C9E858123480642Cf96acbcCE1372dCe1",
    receiveLibraryULNAddress: "0xc02Ab410f0734EFa3F14628780e6e695156024C2",
  },
  POLYGON: {
    layerZeroSrcEndpoint: "0x1a44076050125825900e736c501f859c50fE728c",
    layerZeroDestinationEID: "30109",
    sendLibraryULNAddress: "0x6c26c61a97006888ea9E4FA36584c7df57Cd9dA3",
    receiveLibraryULNAddress: "0x1322871e4ab09Bc7f5717189434f97bBD9546e95",
  },
  AVAX: {
    layerZeroSrcEndpoint: "0x1a44076050125825900e736c501f859c50fE728c",
    layerZeroDestinationEID: "30106",
    sendLibraryULNAddress: "0x197D1333DEA5Fe0D6600E9b396c7f1B1cFCc558a",
    receiveLibraryULNAddress: "0xbf3521d309642FA9B1c91A08609505BA09752c61",
  },
  ARBITRUM: {
    layerZeroSrcEndpoint: "0x1a44076050125825900e736c501f859c50fE728c",
    layerZeroDestinationEID: "30110",
    sendLibraryULNAddress: "0x975bcD720be66659e3EB3C0e4F1866a3020E493A",
    receiveLibraryULNAddress: "0x7B9E184e07a6EE1aC23eAe0fe8D6Be2f663f05e6",
  },
  BASE: {
    layerZeroSrcEndpoint: "0x1a44076050125825900e736c501f859c50fE728c",
    layerZeroDestinationEID: "30184",
    sendLibraryULNAddress: "0xB5320B0B3a13cC860893E2Bd79FCd7e13484Dda2",
    receiveLibraryULNAddress: "0xc70AB6f32772f59fBfc23889Caf4Ba3376C84bAf",
  },
  FANTOM: {
    layerZeroSrcEndpoint: "0x1a44076050125825900e736c501f859c50fE728c",
    layerZeroDestinationEID: "30112",
    sendLibraryULNAddress: "0xC17BaBeF02a937093363220b0FB57De04A535D5E",
    receiveLibraryULNAddress: "0xe1Dd69A2D08dF4eA6a30a91cC061ac70F98aAbe3",
  },
};

export const LAYER_ZERO_CONFIG =
  config.networkType === "testnet"
    ? TESTNET_LAYER_ZERO_CONFIG
    : MAINNET_LAYER_ZERO_CONFIG;
