import { web3 } from "../web3";
import ClaimsAutoRefundAbi from "../abi/claims-auto-refund.json";
import CrossChainClaimsAutoRefundAbi from "../abi/claims-cross-chain-auto-refund.json";
import { autoRefundBytecode, crossChainAutoRefundBytecode } from "../bytecode";
import TokenAbi from "../abi/token.json";

export function getContractAbi(contractType) {
  if (contractType === "auto-refunds-cross-chain") {
    return CrossChainClaimsAutoRefundAbi;
  }

  return ClaimsAutoRefundAbi;
}

export function getContractInstanceAbi(contractAddress, contractType) {
  const currentABI = getContractAbi(contractType);

  try {
    if (web3) {
      const contractInstance = new web3.eth.Contract(
        currentABI,
        contractAddress
      );
      return contractInstance;
    }
  } catch (error) {}
}

export function getContractByteCode(contractType) {
  if (contractType === "auto-refunds-cross-chain") {
    return crossChainAutoRefundBytecode;
  }

  return autoRefundBytecode;
}

export function getTokenInstance(contractAddress) {
  try {
    if (web3) {
      const contractInstance = new web3.eth.Contract(TokenAbi, contractAddress);
      return contractInstance;
    }
  } catch (error) {}
}

export function convertToWei(number) {
  if (number) {
    return web3.utils.toWei(number);
  } else {
    return 0;
  }
}
