import { Icon } from "@iconify/react";
import ShoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import PlusFill from "@iconify/icons-eva/plus-fill";
import FlagFill from "@iconify/icons-eva/flag-fill";

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "manage claims",
    path: "/dashboard",
    icon: getIcon(ShoppingBagFill),
  },
  {
    title: "manage feature flags",
    path: "/dashboard/manage-feature-flags",
    icon: getIcon(FlagFill),
  },
  {
    title: "add claim pool",
    path: "/dashboard/add-claim-pool",
    icon: getIcon(PlusFill),
  },
  {
    title: "solana pool",
    children: [
      {
        title: "create solana pool",
        path: "https://seedify-admin-tool.azurewebsites.net/",
        target: "_blank",
      },
      {
        title: "add solana pool",
        path: "/dashboard/add-solana-pool",
      },
    ],
    icon: getIcon(PlusFill),
  },
  {
    title: "sui pool",
    children: [
      {
        title: "create sui pool",
        path: "https://seedify-sui-admin.netlify.app",
        target: "_blank",
      },
      {
        title: "manage sui contracts",
        path: "/dashboard/manage-sui-contracts",
      },
      {
        title: "add sui pool",
        path: "/dashboard/add-sui-contract",
      },
    ],
    icon: getIcon(PlusFill),
  },
];

export default sidebarConfig;
