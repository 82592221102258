import "react-datetime/css/react-datetime.css";
import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { axiosSeedify } from "src/config";
import { toaster } from "../utils/toast";
import { Card, Stack, TextField, Container, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Page from "../components/Page";
import { services } from "../services/index";
import { utcToLocalTimeStamp } from "src/contractData/function";

class AddSolanaPools extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      contractAddressOfClaim: "",
      tokenAddress: "",
      vestingInfo: "",
      idoName: "",
      totalAmount: 0,
      networkId: "0x38",
      networkSymbol: "SOL",
      networkName: "solana",
      showLoader: false,
      startDate: "",
      utcStartDate: Math.round(new Date().getTime() / 1000),
      phaseNo: 0,
      logo: "",
      jsonFile: null,
      isEdit: false,
      editId: "",
      vestingType: "linear",
      vestTotalArr: [],
      isSFNT: false,
    };
  }

  componentDidMount = async () => {
    const path = await window.location.pathname;
    let newPath = "";
    if (this.props.isCompleted) {
      newPath = await path.replace("/dashboard/edit-solana-pool/", "");
    } else
      newPath = await path.replace("/dashboard/edit-pending-solana-pool/", "");
    if (newPath && path.includes("edit")) this.callEditPoolDetails(newPath);
    console.log(path);
  };

  callEditPoolDetails = async (id) => {
    const url = `claim/${this.props.isCompleted ? "single" : "get-dump"}/${id}`;
    const sendRequest = await services.Get(url);
    if (sendRequest.status === 200) {
      this.setState({ isEdit: true, editId: id });
      const data = sendRequest.data.data;

      this.setState({
        tokenAddress: data.tokenAddress,
        contractAddressOfClaim: data.contractAddress,
        logo: data.logo,
        idoName: data.name,
        phaseNo: data.phaseNo,
        totalAmount: data.amount,
        utcStartDate: data.timestamp,
        linearStartAmount: data.startAmount,
        vestingInfo: data.vestingInfo,
        isSFNT: data.isSnft,
      });
    } else {
      console.log("err");
    }
  };

  updateCompletedPooldetails = async () => {
    this.setState({ showLoader: true });
    try {
      let data = {
        claimId: this.state.editId,
        logo: this.state.logo,
        name: this.state.idoName,
      };
      const url = `/claim/edit`;
      const sendRequest = await services.Post(url, data);

      if (sendRequest.status === 200) {
        this.setState({ showLoader: false });
        this.renderSuccessToast("Pool data updated.");
        return sendRequest.data;
      }
    } catch (err) {
      this.setState({ showLoader: false });
      this.renderErrorToast(err.message);
    }
  };

  renderErrorToast = (text) => {
    this.setState({ showLoader: false });
    return toaster(`${text || "Something went wrong."}`, "error");
  };

  renderSuccessToast = (text) => {
    this.setState({ showLoader: false });
    return toaster(`${text}`, "success");
  };

  addRecords = async (data) => {
    try {
      const url = `/claim/add-dump`;

      const sendRequest = await services.Post(url, data);

      if (sendRequest.status === 200 && sendRequest.data.data) {
        this.renderSuccessToast(
          "Users data is saved. Please proceed with transactions."
        );

        return sendRequest.data;
      } else {
        this.renderErrorToast(sendRequest.data?.message);

        return false;
      }
    } catch (err) {
      this.renderErrorToast(err.message);

      return false;
    }
  };

  addDataToSeedifyV2 = async (id, data) => {
    await axiosSeedify
      .post("/claims/create", data.data)
      .then(() => {
        this.renderSuccessToast("Data was migrated successfully.");
      })
      .catch((error) => {
        this.renderErrorToast("Error while migrating pool.");
        console.error(error);
      });
  };

  newSubmit = async (ev) => {
    ev.preventDefault();
    this.setState({ showLoader: true });
    if (this.props.isCompleted) return this.updateCompletedPooldetails();
    else {
      var data = new FormData(ev.target);
      data.append("csv", this.state.jsonFile);
      data.append("contractAddress", this.state.contractAddressOfClaim);
      data.append("tokenAddress", this.state.tokenAddress);
      data.append("networkSymbol", this.state.networkSymbol);
      data.append("networkName", this.state.networkName);
      data.append("networkId", this.state.networkId);
      data.append("amount", this.state.totalAmount);
      data.append("name", this.state.idoName);
      data.append("phaseNo", this.state.phaseNo);
      data.append("vestingInfo", this.state.vestingInfo);
      data.append("logo", this.state.logo);
      data.append("timestamp", this.state.utcStartDate);
      data.append("vestingType", this.state.vestingType);
      data.append("isSnft", this.state.isSFNT);

      const addedData = await this.addRecords(data);

      if (!addedData) return;

      this.setState({ editId: addedData.data._id });
      this.addDataToSeedifyV2(addedData.data._id, addedData);
    }
  };
  render() {
    let isEdit = this.state.isEdit;
    let completedPoolEdit = this.props.isCompleted;

    return (
      <Page title="Add Solana Pool | Seedify Fund Snapshot">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Solana Claim Token
            </Typography>
          </Stack>

          <Card style={{ padding: "20px" }}>
            <form onSubmit={this.newSubmit}>
              <Stack spacing={3}>
                <TextField
                  disabled={true}
                  fullWidth
                  autoComplete="Select Network"
                  type="text"
                  value="Solana"
                  label="Select Network"
                  readOnly
                />
                <TextField
                  disabled={true}
                  fullWidth
                  autoComplete="Select Vesting Type"
                  type="text"
                  value="Linear Vesting"
                  label="Select Vesting type"
                  readOnly
                />
                <TextField
                  fullWidth
                  autoComplete="Contract Address"
                  type="text"
                  value={this.state.contractAddressOfClaim}
                  label="Contract Address"
                  disabled={isEdit}
                  onChange={(e) => {
                    this.setState({ contractAddressOfClaim: e.target.value });
                  }}
                  required
                />
                <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="Vesting Info Address"
                  type="text"
                  value={this.state.vestingInfo}
                  label="Vesting Info Address"
                  onChange={(e) => {
                    this.setState({ vestingInfo: e.target.value });
                  }}
                  required
                />
                <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="Token Address"
                  type="text"
                  value={this.state.tokenAddress}
                  label="Token Address"
                  onChange={(e) => {
                    this.setState({ tokenAddress: e.target.value });
                  }}
                  required
                />

                <TextField
                  disabled={isEdit && !completedPoolEdit}
                  fullWidth
                  autoComplete="ido"
                  value={this.state.idoName}
                  onChange={(e) => {
                    this.setState({ idoName: e.target.value });
                  }}
                  type="text"
                  label="Ido Name"
                  required
                />

                <TextField
                  disabled={isEdit}
                  fullWidth
                  type={"number"}
                  autoComplete="rewards"
                  label="Total Rewards"
                  value={this.state.totalAmount}
                  onChange={(e) => {
                    this.setState({ totalAmount: e.target.value });
                  }}
                  required
                />
                <TextField
                  disabled={isEdit}
                  fullWidth
                  type="file"
                  accept=".json"
                  onChange={(e) => {
                    this.setState({ jsonFile: e.target.files[0] });
                  }}
                  placeholder={"Upload Claim File in format of json"}
                  required
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    fullWidth
                    disabled={isEdit}
                    renderInput={(props) => (
                      <TextField
                        style={{
                          marginBottom: "25px",
                          display: "flex",
                        }}
                        {...props}
                      />
                    )}
                    label="Select Start Date (UTC)"
                    value={dayjs(
                      utcToLocalTimeStamp(+this.state.utcStartDate * 1000)
                    )}
                    onChange={(newValue) => {
                      const date = newValue;
                      const now_utc = Date.UTC(
                        date.year(),
                        date.month(),
                        date.date(),
                        date.hour(),
                        date.minute(),
                        0
                      );

                      this.setState({
                        startDate: newValue,
                        utcStartDate: now_utc / 1000,
                      });
                    }}
                    required
                  />
                </LocalizationProvider>

                <TextField
                  disabled={completedPoolEdit}
                  type="number"
                  fullWidth
                  autoComplete="Phase"
                  value={this.state.phaseNo}
                  onChange={(e) => {
                    this.setState({ phaseNo: e.target.value });
                  }}
                  label="Phase No"
                  required
                />

                <TextField
                  disabled={isEdit && !completedPoolEdit}
                  fullWidth
                  autoComplete="logo"
                  type="text"
                  label="Logo Url"
                  value={this.state.logo}
                  onChange={(e) => {
                    this.setState({ logo: e.target.value });
                  }}
                  required
                />
              </Stack>
              <br />

              <LoadingButton
                fullWidth
                disabled={!this.state.contractAddressOfClaim}
                size="large"
                type="submit"
                variant="contained"
                loading={
                  this.state.showLoader && this.state.contractAddressOfClaim
                }
                style={{ marginTop: "25px" }}
              >
                Submit
              </LoadingButton>
            </form>
          </Card>
        </Container>
      </Page>
    );
  }
}
export default AddSolanaPools;
