import { default as axios_native } from "axios";

let environment = "dev";

console.error({
  REACT_APP_SEEDIFY_API_KEY: Boolean(process.env.REACT_APP_SEEDIFY_API_KEY),
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
});

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  environment = "prod";
} else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
  environment = "staging";
}

const config = require(`./config.${environment}`).default;

export const axios = axios_native.create({
  baseURL: config.snapshotApi,
});

export const axiosSeedify = axios_native.create({
  baseURL: config.seedifyApi,
  headers: {
    "x-seedify-api-header": process.env.REACT_APP_SEEDIFY_API_KEY,
  },
});

export const axiosSeedworld = axios_native.create({
  baseURL: config.seedworldApi,
});

export default config;
