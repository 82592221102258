import Web3 from 'web3';

let web3 = null;

const metamaskConnectInit = () => {
  // Check if Web3 has been injected by the browser (Mist/MetaMask).
  return new Promise((resolve, reject) => {
    if (typeof window.ethereum !== 'undefined') {

      // Use Mist/MetaMask's provider.
      // enable
      window.ethereum.enable();

      web3 = new Web3(window.ethereum);

      localStorage.setItem('walletConnect', 0);
      resolve(true);
    } else {
      // Handle the case where the user doesn't have web3. Probably
      // show them a message telling them to install Metamask in
      // order to use the app.

      console.log("Wallet not found using read only mode");
      web3 = new Web3(
        new Web3.providers.HttpProvider(
          'https://bsc-dataseed.binance.org/'
          // "https://rinkeby.infura.io/v3/de21e440aade484290be1e3c89e67f28"
        )
      );
      reject(false);
    }
  });
};

if (!web3) {
  metamaskConnectInit();
}

export { web3, metamaskConnectInit };
